<template>
  <!-- Footer -->
  <MDBFooter :text="['center', 'lg-start', 'muted']">
    <MDBContainer>
      <!-- Section: Social media -->
      <section
        class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
      >
        <!-- Left -->
        <div class="me-5 d-none d-lg-block">
          <span>Verbinden met onze Social Media:</span>
        </div>
        <!-- Left -->
        <!-- Right -->
        <div>
          <a href="" class="me-4 text-reset">
            <MDBIcon iconStyle="fab" icon="facebook-f"></MDBIcon>
          </a>
          <a href="" class="me-4 text-reset">
            <MDBIcon iconStyle="fab" icon="twitter"></MDBIcon>
          </a>
          <a href="" class="me-4 text-reset">
            <MDBIcon iconStyle="fab" icon="instagram"></MDBIcon>
          </a>
        </div>
        <!-- Right -->
      </section>
      <!-- Section: Social media -->
    </MDBContainer>
    <!-- Section: Links  -->
    <section class="">
      <MDBContainer class="text-center text-md-start mt-5">
        <!-- Grid row -->
        <MDBRow class="mt-3">
          <!-- Grid column -->
          <MDBCol md="3" lg="4" xl="3" class="mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4">
              <i class="fas fa-gem me-3"></i>Catering Royalty
            </h6>
            <p>
              Het eten, de decoratie en de service die wij bieden zorgen voor
              een onvergetelijke dag.
            </p>
          </MDBCol>
          <!-- Grid column -->
          <!-- Grid column -->
          <MDBCol md="2" lg="2" xl="2" class="mx-auto mb-4"> </MDBCol>
          <!-- Grid column -->
          <!-- Grid column -->
          <MDBCol md="3" lg="2" xl="2" class="mx-auto mb-4"> </MDBCol>
          <!-- Grid column -->
          <!-- Grid column -->
          <MDBCol md="4" lg="3" xl="3" class="mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
            <p>
              <i class="fas fa-envelope me-3"></i>
              <a href="mailto:info@cateringroyalty.eu">info@cateringroyalty.eu</a>
            </p>
            <p><MDBIcon icon="phone" class="me-3" /> <a href="tel:0031644080597"> + 31644080597</a></p>
          </MDBCol>
          <!-- Grid column -->
        </MDBRow>
        <!-- Grid row -->
      </MDBContainer>
    </section>
    <!-- Section: Links  -->
    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05)">
      © 2022 Copyright
      <a class="text-reset fw-bold" href="https://www.cateringroyalty.eu"
        >Catering Royalty</a
      >
    </div>
    <!-- Copyright -->
  </MDBFooter>
  <!-- Footer -->
</template>

<script>
import { MDBFooter, MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";
export default {
  components: {
    MDBFooter,
    MDBRow,
    MDBCol,
    MDBIcon,
  },
};
</script>

<style lang="scss">
footer {
  a {
    color: #757575 !important;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}
</style>

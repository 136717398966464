import { createApp } from "vue";
import App from "./App.vue";

import "mdb-vue-ui-kit/css/mdb.min.css";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
import { MDBContainer } from "mdb-vue-ui-kit";

const app = createApp(App);

app.component("MDBContainer", MDBContainer);
app.mount("#app");

<template>

    <MDBNavbar expand="lg" dark bg="dark" container position="sticky">
      <MDBContainer class="header-container">
        <MDBNavbarNav collapse="navbarExample01" class="mb-2 mb-lg-0">
          <a href="/">
            <img class="logo" src="@/assets/logo.png"/> Catering Royalty
          </a>
        </MDBNavbarNav>
      </MDBContainer>
    </MDBNavbar>

  <header>
    
   <div class="splide">
      <div class="splide__track">
        <ul class="splide__list">
           <li
            v-for="index in 3"
            :key="index"
            class="splide__slide"
          >
            <img
              :src="require(`@/assets/slider/slider-${index}.jpg`)"
            />
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { MDBNavbar, MDBNavbarNav} from 'mdb-vue-ui-kit';
import Splide from '@splidejs/splide';

export default {
   components: {
    MDBNavbar,
    MDBNavbarNav
  },
  mounted: function(){

    new Splide( '.splide', {
      type   : 'loop',
      width: '1325px',
    } ).mount();
  }
  
};
</script>

<style lang="scss">
.logo {
  max-width: 75px;
}

.header-container {
  padding: 0;

  a {
    color: #ffffff !important;
    font-size: 28px;
    line-height: 28px;
  }

}
.splide {
  margin: 0 auto;

  .splide__slide img {
    max-width: 100%;
    height: auto;
  }
}
</style>

<template>
  <MDBContainer>
    <lightgallery
      :settings="{ speed: 500, plugins: plugins }"
      :onInit="onInit"
      :onBeforeSlide="onBeforeSlide"
    >
      <a
        v-for="index in 40"
        :key="index"
        class="gallery-item"
        :data-src="require(`@/assets/gallery/${index}.jpg`)"
        data-sub-html=""
      >
        <img
          class="img-responsive"
          :src="require(`@/assets/gallery/thumbnails/${index}-thumbnail.jpg`)"
        />
      </a>

      <a
        class="gallery-item"
        :data-video="
          JSON.stringify({
            source: [
              {
                src: require('@/assets/gallery/video-1.mp4'),
                type: 'video/mp4',
              },
            ],
            attributes: { preload: false, controls: true },
          })
        "
        data-poster=""
        data-sub-html=""
      >
        <img
          class="img-responsive"
          :src="require('@/assets/gallery/video-1-poster.png')"
        />
      </a>
      <a
        class="gallery-item"
        :data-video="
          JSON.stringify({
            source: [
              {
                src: require('@/assets/gallery/video-2.mp4'),
                type: 'video/mp4',
              },
            ],
            attributes: { preload: false, controls: true },
          })
        "
        data-poster=""
        data-sub-html=""
      >
        <img
          class="img-responsive"
          :src="require('@/assets/gallery/video-2-poster.png')"
        />
      </a>
      <a
        class="gallery-item"
        :data-video="
          JSON.stringify({
            source: [
              {
                src: require('@/assets/gallery/video-3.mp4'),
                type: 'video/mp4',
              },
            ],
            attributes: { preload: false, controls: true },
          })
        "
        data-poster=""
        data-sub-html=""
      >
        <img
          class="img-responsive"
          :src="require('@/assets/gallery/video-3-poster.png')"
        />
      </a>
      <a
        class="gallery-item"
        :data-video="
          JSON.stringify({
            source: [
              {
                src: require('@/assets/gallery/video-4.mp4'),
                type: 'video/mp4',
              },
            ],
            attributes: { preload: false, controls: true },
          })
        "
        data-poster=""
        data-sub-html=""
      >
        <img
          class="img-responsive"
          :src="require('@/assets/gallery/video-4-poster.png')"
        />
      </a>
    </lightgallery>
  </MDBContainer>
</template>

<script>
import Lightgallery from "lightgallery/vue";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

export default {
  name: "App",
  components: {
    Lightgallery,
  },
  data: () => ({
    plugins: [lgZoom, lgVideo],
  }),
  methods: {
    onInit: () => {
      console.log("lightGallery has been initialized");
    },
    onBeforeSlide: () => {
      console.log("calling before slide");
    },
  },
};
</script>
<style lang="css">
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-video.css");

.container {
  padding: 24px 0;
}

.gallery-item {
  margin: 5px;
  display: inline-block;
}
</style>
